import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Col,
  Card,
  CardBody,
  CardTitle,
  Row,
  Form,
  Button,
} from "reactstrap"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import { getPos, getReport } from "../../../services/mcp.service"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import moment from "moment"
import * as FileSaver from "file-saver"
import * as XLSX from "xlsx"
import { set } from "lodash"

const Report = props => {
  const dayRange = [
    { value: 1, label: "1 хоног", key: "1" },
    { value: 7, label: "7 хоног", key: "7"},
    { value: 30, label: "30 хоног", key: "30" },
    { value: 90, label: "90 хоног", key: "90" },
  ]
  const columns = [
    {
      dataField: "created_at",
      text: "Огноо",
      sort: true,
      // formatter: (col, row) => {
      //   console.log(col, "ognoo")
      //   return col.moment()
      // },
    },
    {
      dataField: "branch_code",
      text: "UA Код",
      sort: true,
    },
    {
      dataField: "branch_name",
      text: "Дэлгүүрийн нэр",
      sort: true,
    },
    {
      dataField: "pos_id",
      text: "ПОС",
      sort: true,
    },
    {
      dataField: "_id",
      text: "Transaction ID",
      sort: true,
    },
    {
      dataField: "core.bill_number",
      text: "Bill Number",
      sort: true,
    },
    {
      dataField: "pos.printed_total_point",
      text: "Хэвлэсэн U-Point",
      sort: true,
    },
    {
      dataField: "total_point",
      text: "Уншуулсан U-Point",
      sort: true,
    },
    {
      dataField: "core.processed_at",
      text: "Уншуулсан огноо",
      sort: true,
      formatter: (col, row) => {
        console.log(col, "col")
      },
    },
  ]
  const [productData, setData] = useState([])
  const [pos, setPos] = useState([])
  const [posCreate, setPosCreate] = useState("5f4361361e030228fd9f7b02")
  const [uaCode, setUaCode] = useState("")
  const [startDate, setStartDate] = useState("")
  const [day, setDay] = useState("1 хоног")
  const [dayValue, setDayValue] = useState(1)
  const [endDate, setEndDate] = useState("")

  useEffect(async () => {
    try {
      const pos = await getPos()
      console.log(pos.data, "pos")
      setPos(pos.data)
    } catch (err) {
      console.log(err)
    }
  }, [])
  const getRep = async () => {
    const data = {
      start_date: startDate,
      end_date: endDate,
      // end_date: endDate.hour(23, 59, 59).toISOString(),
      ua_code: uaCode,
      pos_id: posCreate,
    }
    try {
      const report = await getReport(data)
      if (report.status === "error") throw report
      else toastr.success("Амжилттай")
      setData(report.data)
    } catch (err) {
      toastr.error("Сүлжээний алдаа")
      console.log(err.message, "errror")
    }
  }
  const pageOptions = {
    sizePerPage: 50,
    totalSize: productData.length,
    custom: true,
  }

  const selectRow = {
    mode: "checkbox",
  }
  const saveExcel = () => {
    getRep()
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"

    const res =  productData.map((item) => {
      return {
        "Огноо": item?.created_at,
        "UA Код": item?.branch_code,
        "Дэлгүүрийн нэр": item?.branch_name,
        "ПОС": item?.pos_id,
        "Transaction ID": item?._id,
        "Bill Number": item?.core?.bill_number,
        "Хэвлэсэн U-Point": item?.pos?.printed_total_point,
        "Уншуулсан U-Point": item?.total_point,
        "Уншуулсан огноо": item?.core?.processed_at,
      }})
    const ws = XLSX.utils.json_to_sheet(res)
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, "Report" + ".xlsx")
    toastr.success("Амжилттай татагдлаа")
  }

  const { SearchBar } = Search
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Суваг дэлгүүрийн тайлан</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Tables" breadcrumbItem="U-Point онооны тайлан" />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">Дэлгүүрийн Жагсаалт </CardTitle>
                  <Row>
                    {/* <Col xs={12}> */}

                    {/* <form> */}
                    <div data-repeater-list="group-a">
                      <div data-repeater-item className="row">
                        <div className="mb-3 col-lg-2">
                          <label htmlFor="name">Эхлэх огноо</label>
                          <input
                            type="date"
                            id="name"
                            name="untyped-input"
                            className="form-control"
                            value={startDate}
                            required
                            onChange={e => {
                              setStartDate(e.target.value)
                              setEndDate( moment(e.target.value).add(dayValue+1, 'days').toISOString().substring(0, 10))
                            }}
                          />
                        </div>
                        <div className="mb-3 col-lg-2">
                          <label htmlFor="email">Дуусах огноо</label>
                          <select
                            className="form-control"
                            type="text"
                            value={day}
                            onChange={e => {
                              setDay(e.target.value)
                              setDayValue(parseInt(e.target.value.split(" ")[0]))
                              setEndDate(moment(startDate).add(parseInt(e.target.value.split(" ")[0])+1, 'days').toISOString().substring(0, 10))
                            }}
                          >
                            {dayRange.map((param) => {
                              return (
                                <option
                                  key={param.key}
                                  name={param.label}
                                  value={param.label}
                                >
                                  {param.label}
                                </option>
                              )
                            })}
                          </select>
                          {/* <input
                            type="date"
                            required
                            id="email"
                            className="form-control"
                            value={endDate}
                            onChange={e => {
                              setEndDate(e.target.value)
                            }}
                          /> */}
                        </div>

                        <div className="mb-3 col-lg-2">
                          <label htmlFor="subject">ПОС</label>
                          <select
                            className="form-control"
                            type="text"
                            value={posCreate}
                            onChange={e => {
                              setPosCreate(e.target.value)
                            }}
                          >
                            {pos.map(param => {
                              return (
                                <option
                                  key={param._id}
                                  name={param.name}
                                  value={param._id}
                                >
                                  {param.name}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                        <div className="mb-3 col-lg-2">
                          <label htmlFor="subject">UA Код</label>
                          <input
                            type="text"
                            id="subject"
                            className="form-control"
                            onChange={e => {
                              setUaCode(e.target.value)
                            }}
                            value={uaCode}
                          />
                        </div>

                        <Col lg={2} className="align-self-center">
                          <div className="d-grid">
                            <input
                              data-repeater-delete
                              type="submit"
                              className="btn btn-primary"
                              value="Шүүх"
                              onClick={getRep}
                            />
                          </div>
                        </Col>
                      </div>
                    </div>
                    {/* </form> */}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={columns}
                    data={productData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={productData}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col md="10">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="2">
                                <Button
                                  color="secondary"
                                  className="btn btn-secondary "
                                  onClick={saveExcel}
                                >
                                  Excel - ээр татах
                                </Button>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    // defaultSorted={defaultSorted}
                                    selectRow={selectRow}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Report
