import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Input,
} from "reactstrap"
import Dropzone from "react-dropzone"
import { Link } from "react-router-dom"
import DetailPicture from "./detailPicture"
import ThumbPicture from "./thumbPicture"

const addCoupon = props => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Create Coupon</title>
        </MetaTags>
        <Container fluid>
          <h3>Create Coupon</h3>
          <Card>
            <CardBody>
              <CardTitle className="h4">Купон үүсгэх</CardTitle>
              <p className="card-title-desc"></p>
              <div>
                <Row>
                  <Col lg={4}>
                    <div className="mt-4">
                      <label className="form-label">Нөхцөлийн дугаар</label>
                      <input
                        className="form-control form-control-sm"
                        type="text"
                      />
                    </div>
                    <label className="col-md-6 col-form-label">
                      Аппликейшн дээр харуулах хугацаа
                    </label>
                    <Row>
                      <div className="col-md-6">
                        <label className="form-label">Эхлэх өдөр</label>
                        <input className="form-control" type="datetime-local" />
                      </div>
                      <div className="col-md-6">
                        <label className="form-label">Дуусах өдөр</label>
                        <input className="form-control" type="datetime-local" />
                      </div>
                    </Row>
                    <div className="mt-4">
                      <label className="form-label">Байгууллагын нэр</label>
                      <input
                        className="form-control form-control-sm"
                        type="text"
                      />
                    </div>
                    <div className="mt-4">
                      <label className="form-label">Купоны нэр</label>
                      <input
                        className="form-control form-control-sm"
                        type="text"
                      />
                    </div>
                    <div className="mt-4">
                      <label className="form-label">Купоны дэлгэрэнгүй</label>
                      <Input
                        type="textarea"
                        id="textarea"
                        maxLength="225"
                        rows="3"
                        placeholder="This textarea has a limit of 225 chars."
                      />
                    </div>
                    <div className="mt-4">
                      <label className="form-label">
                        Купоны дэлгэрэнгүй(идэвхжүүлсний дараах)
                      </label>
                      <Input
                        type="textarea"
                        id="textarea"
                        maxLength="225"
                        rows="3"
                        placeholder="This textarea has a limit of 225 chars."
                      />
                    </div>
                    <div className="mt-4">
                      <label className="form-label">Үндсэн үнэ</label>
                      <input
                        className="form-control form-control-sm"
                        type="number"
                      />
                    </div>
                    <div className="mt-4">
                      <label className="form-label">Хямдралтай үнэ</label>
                      <input
                        className="form-control form-control-sm"
                        type="number"
                      />
                    </div>
                    <div className="mt-4">
                      <label className="form-label">Хямдрал</label>
                      <input
                        className="form-control form-control-sm"
                        type="number"
                      />
                    </div>
                    <div className="mt-4">
                      <label className="form-label">U-Point оноо</label>
                      <input
                        className="form-control form-control-sm"
                        type="number"
                      />
                    </div>
                    <div className="mt-4">
                      <label className="form-label">Купоны хугацаа</label>
                      <input
                        className="form-control form-control-sm"
                        type="number"
                        defaultValue="Купоныг идэвхжүүлснээс хойш ашиглах боломжтой хугацаа"
                      />
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="mt-4">
                      <label className="form-label">Купон код</label>
                      <select className="form-control form-control-sm">
                        <option value={""}>UA үүсгэж өгөх</option>
                        <option value={0}>Купон код оруулах</option>
                      </select>
                    </div>
                    <div className="mt-4">
                      <label className="form-label">Сегмент</label>
                      <select className="form-control form-control-sm"></select>
                    </div>
                    <div className="mt-4">
                      <label className="form-label">TAG</label>
                      <select className="form-control form-control-sm">
                        <option value={""}>Collective</option>
                        <option value={0}>Ажилтан</option>
                        <option value={0}>Дэлгүүр</option>
                      </select>
                    </div>
                    <div className="mt-4">
                      <label className="form-label">Тоо ширхэг</label>
                      <input
                        className="form-control form-control-sm"
                        type="number"
                        defaultValue=""
                      />
                    </div>
                    <div className="mt-4">
                      <label className="form-label">
                        Гүйлгээний байгууллага
                      </label>
                      <select className="form-control form-control-sm"></select>
                    </div>
                    <div className="mt-4">
                      <h4 className="form-label">Контент оруулах</h4>
                    </div>
                    <div className="mt-4">
                      <label className="form-label">Thumbnail зураг</label>
                      <ThumbPicture />
                    </div>
                    <div className="mt-4">
                      <label className="form-label">Detail зураг</label>
                      <DetailPicture />
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="mt-4">
                      <label className="form-label">Small Input</label>
                      <input
                        className="form-control form-control-sm"
                        type="text"
                        placeholder=".form-control-sm"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default addCoupon
