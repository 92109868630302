import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { getPos, getShop, createBatch } from "../../../services/mcp.service"
import { Link, useHistory } from "react-router-dom"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import Badge from "reactstrap/lib/Badge"
import moment from "moment"
import {
  Container,
  Col,
  Card,
  CardBody,
  CardTitle,
  Row,
  Form,
  Button,
  Modal,
  Table,
} from "reactstrap"
import BootstrapTable from "react-bootstrap-table-next"

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import * as FileSaver from "file-saver"
import * as XLSX from "xlsx"

import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { result } from "lodash"

const Shop = props => {
  const columns = [
    {
      dataField: "shortcode",
      text: "UA код",
      sort: true,
    },
    {
      dataField: "name",
      text: "Дэлгүүрийн нэр",
      sort: true,
    },
    {
      dataField: "address.address_string",
      text: "Хаяг",
      sort: true,
      // headerStyle: (colum, colIndex) => {
      //   return { width: '80px', textAlign: 'center' };
      // }
      formatter: (col, row) => {
        return (
          <span
            style={{
              display: "block",
              width: 200,
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {col}
          </span>
        )
      },
    },
    {
      dataField: "misc_info.cash_vendor[0].name",
      text: "ПОС",
      sort: true,
    },
    {
      dataField: "status",
      text: "Төлөв",
      sort: true,
      formatter: (col, row) => {
        if (col == 0) {
          return <Badge style={{ backgroundColor: "black" }}>Хаагдсан</Badge>
        } else if (col == 1) {
          return <Badge style={{ backgroundColor: "green" }}>Нээлттэй</Badge>
        } else return <Badge style={{ backgroundColor: "gray" }}>Ноорог</Badge>
      },
    },
    {
      dataField: "work_status",
      text: "Ажиллагааны статус",
      sort: true,
      formatter: (col, row) => {
        if (col === 0) {
          return <Badge style={{ backgroundColor: "#7DE86A" }}>Шинэ</Badge>
        } else if (col === 1) {
          return <Badge style={{ backgroundColor: "#2DAD35" }}>Хэвийн</Badge>
        } else if (col === 2) {
          return (
            <Badge style={{ backgroundColor: "#16591A" }}>
              Гүйлгээ хэвийн / Агент ажиллагаагүй
            </Badge>
          )
        } else if (col === 3) {
          return (
            <Badge style={{ backgroundColor: "#07360A" }}>
              Агент хэвийн / Гүйлгээгүй
            </Badge>
          )
        } else if (col === 4) {
          return (
            <Badge style={{ backgroundColor: "#D6AC0F" }}>
              Сүүлийн 7 хоног ажиллаагүй
            </Badge>
          )
        } else if (col === 5) {
          return (
            <Badge style={{ backgroundColor: "#E7810D" }}>
              Сүүлийн 14 хоног ажиллаагүй
            </Badge>
          )
        } else if (col === 6) {
          return (
            <Badge style={{ backgroundColor: "#EB2010" }}>
              14+ хоног ажиллаагүй
            </Badge>
          )
        }
      },
    },
    {
      dataField: "last_sync_date",
      text: "Сүүлийн sync",
      sort: true,
      formatter: (col, row) => {
        if (col == null) {
          return null
        } else return moment(col).format("L")
      },
    },
    {
      dataField: "last_transaction_date",
      text: "Сүүлийн гүйлгээ",
      sort: true,
      formatter: (col, row) => {
        if (col == null) {
          return null
        } else return moment(col).format("L")
      },
    },
  ]
  const pageOptions = {
    sizePerPage: 20,
    custom: true,
  }

  const { SearchBar } = Search
  const [shops, setShops] = useState([])
  const [pos, setPos] = useState([])
  const [status, setStatus] = useState("")
  const [workStatus, setworkStatus] = useState("")
  const [posFilter, setPosFilter] = useState("")
  const [reg_no, setRegNo] = useState("")
  const [cash_vendor, setCashVendor] = useState("5f4361361e030228fd9f7b02")
  const [quantity, setQuantity] = useState(1)
  const [modal_standard, setmodal_standard] = useState(false)

  const FilterSubmit = async () => {
    const shops = await getShop(status, workStatus, posFilter, reg_no)
    setShops(shops.data)
  }
  function tog_standard() {
    setmodal_standard(!modal_standard)
    // removeBodyCss()
  }
  const addBatch = async () => {
    const batchData = {
      cash_vendor: cash_vendor,
      quantity: quantity,
    }
    try {
      const newBatch = await createBatch(batchData)
      if (newBatch.status === "error") throw newBatch
      else {
        console.log(newBatch)
        const fileType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
        const ws = XLSX.utils.json_to_sheet(newBatch.data)
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
        const data = new Blob([excelBuffer], { type: fileType })
        FileSaver.saveAs(data, "BatchData" + ".xlsx")
        toastr.success("Амжилттай")
        // window.location.reload()
      }
    } catch (err) {
      toastr.error(err.message)
      console.log(err, "errror")
    }
  }
  const saveExcel = () => {
    getShop()
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" 
    const res =  shops.map((item) => {
          return {
            "UA код": item?.shortcode,
            "Дэлгүүрийн нэр": item?.name,
            "Хаяг": item?.address?.address_string,
            "ПОС": item?.pos?.misc_info?.cash_vendor[0]?.name,
            "Төлөв": item?.work_status,
            "Сүүлийн sync": item?.last_sync_date,
            "Сүүлийн гүйлгээ": item?.last_transaction_date,
          }
    })

    const ws = XLSX.utils.json_to_sheet(res)
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, "Shops" + ".xlsx")
    toastr.success("Амжилттай татагдлаа")
  }

  useEffect(async () => {
    try {
      const shops = await getShop()
      const pos = await getPos()

      setShops(shops.data)
      setPos(pos.data)
    } catch (err) {
      console.log(err)
    }
  }, [])

  const rowEvents = {
    onDoubleClick: (e, row, rowIndex) => {
      history.push(`/channel/info/${row.merchant}/${row._id}`)
    },
  }
  const history = useHistory()

  const { ExportCSVButton } = CSVExport
  const handleClick = () => {
    props.onExport()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Дэлгүүрийн бүртгэл</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Tables" breadcrumbItem="Дэлгүүрийн бүртгэл" />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">Дэлгүүрийн Жагсаалт </CardTitle>
                  <Row>
                    <div data-repeater-list="group-a">
                      <div data-repeater-item className="row">
                        <div className="mb-3 col-lg-2">
                          <label htmlFor="name">Регистрийн дугаар</label>
                          <input
                            className="form-control"
                            value={reg_no}
                            onChange={e => {
                              setRegNo(e.target.value)
                            }}
                          ></input>
                        </div>
                        <div className="mb-3 col-lg-2">
                          <label htmlFor="name">Төлөв</label>
                          <select
                            className="form-control"
                            value={status}
                            onChange={e => {
                              setStatus(e.target.value)
                            }}
                          >
                            <option value={""}>Бүгд</option>
                            <option value={-1}>Ноорог</option>
                            <option value={1}>Нээлттэй</option>
                            <option value={0}>Хаагдсан</option>
                          </select>
                        </div>
                        <div className="mb-3 col-lg-2">
                          <label htmlFor="email">Ажиллагааны статус</label>
                          <select
                            className="form-control"
                            value={workStatus}
                            onChange={e => {
                              setworkStatus(e.target.value)
                            }}
                          >
                            <option value={""}>Бүгд</option>
                            <option value={0}>Шинэ</option>
                            <option value={1}>Хэвийн </option>
                            <option value={2}>
                              Гүйлгээ хэвийн / Агент ажиллагаагүй{" "}
                            </option>
                            <option value={3}>Агент хэвийн / Гүйлгээгүй</option>
                            <option value={4}>
                              Сүүлийн 7 хоног ажиллаагүй{" "}
                            </option>
                            <option value={5}>
                              Сүүлийн 14 хоног ажиллаагүй
                            </option>
                            <option value={6}>14+ хоног ажиллаагүй</option>
                          </select>
                        </div>

                        <div className="mb-3 col-lg-2">
                          <label htmlFor="subject">ПОС</label>
                          <select
                            className="form-control"
                            value={posFilter}
                            onChange={e => {
                              setPosFilter(e.target.value)
                              console.log(e.target.value, "value")
                            }}
                          >
                            <option value={""}>Бүгд</option>
                            {pos.map(param => {
                              return (
                                <option
                                  key={param._id}
                                  name={param.name}
                                  value={param._id}
                                >
                                  {param.name}
                                </option>
                              )
                            })}
                          </select>
                        </div>

                        <Col lg={2} className="align-self-center">
                          <div className="d-grid">
                            <input
                              data-repeater-delete
                              type="submit"
                              className="btn btn-primary"
                              value="Шүүх"
                              onClick={FilterSubmit}
                            />
                          </div>
                        </Col>
                      </div>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="_id"
                    columns={columns}
                    data={shops}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        columns={columns}
                        data={shops}
                        search
                        exportCSV
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col md="6">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="2">
                                <Button
                                  color="secondary"
                                  className="btn btn-secondary "
                                  onClick={saveExcel}
                                >
                                  Excel - ээр татах
                                </Button>
                              </Col>
                              <Col sm="2">
                                <Link
                                  color="success"
                                  className="btn btn-success "
                                  to="./addShop"
                                  // style={{width: "150px"}}
                                >
                                  Шинэ дэлгүүр нэмэх
                                </Link>
                              </Col>
                              <Col sm="2">
                                <Button
                                  color="info"
                                  className="btn btn-info "
                                  onClick={tog_standard}
                                >
                                  Batch үүсгэх
                                </Button>
                                <Modal
                                  size="lg"
                                  isOpen={modal_standard}
                                  toggle={() => {
                                    tog_standard()
                                  }}
                                >
                                  <div className="modal-header">
                                    <h5
                                      className="modal-title mt-0"
                                      id="myLargeModalLabel"
                                    >
                                      Batch үүсгэх
                                    </h5>
                                    <button
                                      onClick={() => {
                                        setmodal_standard(false)
                                      }}
                                      type="button"
                                      className="close"
                                      data-dismiss="modal"
                                      aria-label="Close"
                                    >
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                  <div className="modal-body">
                                    <Row>
                                      <Card>
                                        <CardBody>
                                          <Row className="mb-3">
                                            <label
                                              htmlFor="example-search-input"
                                              className="col-md-2 col-form-label"
                                            >
                                              Кассын систем
                                            </label>
                                            <div className="col-md-10">
                                              <select
                                                className="form-control"
                                                type="text"
                                                value={cash_vendor}
                                                onChange={e => {
                                                  setCashVendor(e.target.value)
                                                }}
                                              >
                                                {pos.map(param => {
                                                  return (
                                                    <option
                                                      key={param._id}
                                                      name={param.name}
                                                      value={param._id}
                                                    >
                                                      {param.name}
                                                    </option>
                                                  )
                                                })}
                                              </select>
                                            </div>
                                          </Row>
                                          <Row className="mb-3">
                                            <label
                                              htmlFor="example-text-input"
                                              className="col-md-2 col-form-label"
                                            >
                                              Тоо хэмжээ
                                            </label>
                                            <div className="col-md-10">
                                              <input
                                                className="form-control"
                                                type="number"
                                                value={quantity}
                                                onChange={e => {
                                                  setQuantity(e.target.value)
                                                }}
                                              />
                                            </div>
                                          </Row>
                                          <div className="modal-footer">
                                            <button
                                              type="button"
                                              onClick={() => {
                                                tog_standard()
                                              }}
                                              className="btn btn-secondary "
                                              data-dismiss="modal"
                                            >
                                              Болих
                                            </button>
                                            <button
                                              type="button"
                                              className="btn btn-primary "
                                              onClick={() => {
                                                tog_standard()
                                                addBatch()
                                              }}
                                            >
                                              Үүсгэх
                                            </button>
                                          </div>
                                        </CardBody>
                                      </Card>
                                    </Row>
                                  </div>
                                </Modal>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  {/* <ExportCSVButton { ...props.csvProps }>Export CSV!!</ExportCSVButton> */}
                                  <BootstrapTable
                                    // keyField={"_id"}
                                    bordered={false}
                                    striped={false}
                                    rowEvents={rowEvents}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Shop
