import axios from "axios"
import accessToken from "./jwt-token-access/accessToken"

// const API_URL = "http://10.10.10.123:3040"
   const API_URL = "https://api.astral.upoint.mn"
// const API_URL = "http://localhost:3040"

// const axiosApi = axios.create({
//   baseURL: API_URL,
// })

// axiosApi.defaults.headers.common["Authorization"] = token

// axiosApi.interceptors.response.use(
//   response => response,
//   error => Promise.reject(error)
// )

export async function get(url, config = {}) {
  return await axios
    .get(API_URL + url, { ...config })
    .then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axios
    .post(API_URL + url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axios
    .put(API_URL + url, { ...data }, { ...config })
    .then(response => response.data)
}
export async function patch(url, data, config = {}) {
  return axios
    .patch(API_URL + url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axios
    .delete(API_URL + url, { ...config })
    .then(response => response.data)
}
