import React, { useState } from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
// Dashboard
// import Dashboard from "../pages/Channel/Dashboard/index"
import Shop from "../pages/Channel/Shop"
import Report from "../pages/Channel/Report"
import addShop from "../pages/Channel/Shop/addShop"
import Info from "../pages/Channel/Info"
import Logs from "../pages/Channel/Logs"
import { authService } from "services/auth.service"
//Business
import Coupon from "../pages/Business/Coupon/index"
import Bus_Dashboard from "../pages/Business/Dashboard/index"
import addCoupon from "../pages/Business/Coupon/addCoupon"

const routes = props => {
  const [views, setViews] = useState("")
  const token = authService.accessToken
  const decoded = jwt_decode(token)
  useEffect(() => {
    setRole(decoded.views)
  }, [])
}

const userRoutes = [
  //MCP
  { path: "/channel/dashboard", component: Shop },
  { path: "/channel/shop", component: Shop },
  { path: "/channel/report", component: Report },
  { path: "/channel/info/:merchant/:id", component: Info },
  { path: "/channel/addShop", component: addShop },
  { path: "/channel/logs", component: Logs },

  // //Business
  { path: "/profile", component: UserProfile },
  { path: "/business/dashboard", component: Bus_Dashboard },
  { path: "/business/coupon", component: Coupon },
  { path: "/business/addCoupon", component: addCoupon },

  // this route should be at the end of all other routes
  {
    path: "/",
    exact: true,
    component: () => <Redirect to={`/${views[0]}`} />,
  },
]

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
]

export { userRoutes, authRoutes, routes }
