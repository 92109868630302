import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import {
  getPos,
  getShop,
  getBranch,
  updateBranch,
  updateMerchant,
  updatePos,
  createPos,
  uploadFile as UploadFile,
} from "../../../services/mcp.service"
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"
import Badge from "reactstrap/lib/Badge"
import Select from "react-select"
import moment from "moment"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Input,
  Modal,
  Table,
  Button,
  FormGroup,
  Form,
  Dropdown, 
  DropdownToggle, 
  DropdownMenu, 
  DropdownItem
} from "reactstrap"
import axios from "axios"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import classnames from "classnames"
import { useRef } from "react"

//import images
// import small from "../../../assets/images/small/img-2.jpg"
// import small2 from "../../../assets/images/small/img-6.jpg"

const TabOne = ({ branch, address=[] }) => {
  const [activeTab, toggleTab] = useState("1")
  const [modal_standard, setmodal_standard] = useState(false)
  const [modal_createPos, setmodal_createPos] = useState(false)
  const [modal_branch, setmodal_branch] = useState(false)
  const [modal_address, setmodal_address] = useState(false)
  const [modal_merchant, setmodal_merchant] = useState(false)
  const [modal_owner, setmodal_owner] = useState(false)
  const [posView, setPosView] = useState({})
  const [posId, setPosId] = useState(0)
  const [uaCode, setUaCode] = useState("")
  const [name, setName] = useState("")
  const [cashVendor, setCashVendor] = useState("")
  const [cashNo, setCashNo] = useState("")
  const [timeTable, setTimeTable] = useState("")
  const [status, setStatus] = useState("")
  const [type, setType] = useState("")

  const [closeDesc, setCloseDesc] = useState("")
  const [country, setCountry] = useState("")
  const [countryList, setCountryList] = useState([])
  const [city, setCity] = useState("")
  const [cityList, setCityList] = useState([])
  const [district, setDisctrict] = useState("")
  const [districtList, setDistrictList] = useState([])
  const [district1, setDisctrict1] = useState("")
  const [desc, setDesc] = useState("")
  const [coordinates1, setCoordinates1] = useState("")
  const [coordinates2, setCoordinates2] = useState("")
  const [merchantReg, setMerchantReg] = useState("")
  const [merchantName, setMerchantName] = useState("")
  const [mcCode, setMcCode] = useState("")
  const [merchantId, setMerchantId] = useState("")
  const [contractType, setContractType] = useState("")
  const [ownerReg, setOwnerReg] = useState("")
  const [ownerName, setOwnerName] = useState("")
  const [ownerNumber, setOwnerNumber] = useState("")
  const [ownerEmail, setOwnerEmail] = useState("")
  const [ownerBank, setOwnerBank] = useState("")

  const [ownerBankType, setOwnerBankType] = useState("")
  const [merchantNumber, setMerchantNumber] = useState("")
  const [file, setFile] = useState()
  const [pos_status, setPosStatus] = useState("")
  const [selectedMulti, setselectedMulti] = useState(null)
  const [errors, setErrors] = useState("")
  const [pos, setPos] = useState([])
  const [does_have_address, SetAddress] = useState("")
  const [address_date, SetAddressDate] = useState("")
  const [address_type, SetAddressType] = useState("")
  const [other_decorations, SetOtherDecoration] = useState("")
  const bottomRef = useRef(null)

  const [dropdownCountyOpen, setDropdownCountryOpen] = useState(false);
  const [dropdownCityOpen, setDropdownCityOpen] = useState(false);
  const [dropdownDistrictOpen, setDropdownDistrictOpen] = useState(false);

  const toggleCountry = () => setDropdownCountryOpen((prevState) => !prevState);
  const toggleCity = () => setDropdownCityOpen((prevState) => !prevState);
  const toggleDistrict = () => setDropdownDistrictOpen((prevState) => !prevState);

  useEffect(async () => {
    setUaCode(branch?.shortcode)
    setName(branch?.name)
    setCashVendor(
      branch?.misc_info?.cash_vendor?.length === 0
        ? " "
        : branch?.misc_info?.cash_vendor[0]?._id
    )
    setCashNo(branch?.pos?.length)
    setTimeTable(branch?.timetable)
    setStatus(branch?.status)
    setType(branch?.type)
    setCloseDesc(branch?.closeDesc)
    setCoordinates1(branch?.merchant?.address?.position?.coordinates[0])
    setCoordinates2(branch?.merchant?.address?.position?.coordinates[1])
    setCountry(branch?.address?.country?.name)
    setCity(branch?.address?.province?.name)
    setDisctrict(branch?.address?.district?.name)
    setDisctrict(branch?.address?.district1?.name)
    setDesc(branch?.merchant?.address?.address_string)
    setMerchantReg(branch?.merchant?.registration_number)
    setMerchantName(branch.merchant?.name)
    setMcCode(branch?.merchant?.shortcode)
    setMerchantId(branch?.merchant?.merchant)
    setContractType(branch?.merchant?.contract_type)
    setOwnerReg(branch?.merchant?.owner_info?.registration_number)
    setOwnerEmail(branch?.merchant?.owner_info?.email)
    setOwnerBank(branch?.merchant?.owner_info?.bank_account)
    setOwnerBankType(branch?.merchant?.owner_info?.mobile_2)
    setOwnerName(branch?.merchant?.owner_info?.name)
    setOwnerNumber(branch?.merchant?.owner_info?.mobile_1)
    setMerchantNumber(branch?.merchant?.phone_number)
    setPosStatus(branch?.pos ? branch?.pos[posId]?.status : "")
    SetAddress(branch?.does_have_address)
    SetAddressDate(branch?.address_date)
    SetAddressType(branch?.address_type)
    SetOtherDecoration(branch?.other_decorations)
    console.log("does_have_address", branch?.does_have_address)
    bottomRef.current?.scrollIntoView({ behavior: "smooth" })

    try {
      const pos = await getPos()
      setPos(pos.data)
      // console.log(cashVendor)
    } catch (err) {
      console.log(err)
    }
    setCountryList([ ...address?.map(item => item.label)])
    const cities = await address?.filter(item => item.label === (country ? country : 'Монгол улс'))[0]?.children || []
    setCityList([ ...cities?.map(item => item.label)])
    const districts = await cities?.filter(item => item.label === (city ? city : 'Улаанбаатар хот'))[0]?.children || []
    setDistrictList([ ...districts?.map(item => item.label)])

    // setFile()
  }, [branch, address])
  // console.log(districtList, "countryList")
  // function handleMulti() {
  //   setselectedMulti(branch?.bank_type)
  // }

  const toggle = tab => {
    if (activeTab !== tab) toggleTab(tab)
  }
  function tog_standard() {
    setmodal_standard(!modal_standard)
    // removeBodyCss()
  }
  function tog_createPos() {
    setmodal_createPos(!modal_createPos)
    // removeBodyCss()
  }

  function tog_branch() {
    setmodal_branch(!modal_standard)
    // removeBodyCss()
  }
  function tog_address() {
    setmodal_address(!modal_standard)
    // removeBodyCss()
  }
  function tog_merchant() {
    setmodal_merchant(!modal_standard)
    // removeBodyCss()
  }
  function tog_owner() {
    setmodal_owner(!modal_standard)
    // removeBodyCss()
  }
  const uploadFile = async ({ target }) => {
    const file = target.files[0]
    console.log(file)
    if (file !== null) {
      const formData = new FormData()
      formData.append("pdf", file)
      const uploaded = await axios.post(
        "https://api.astral.upoint.mn/v1/file/upload",
        formData
      )
      console.log(`"${uploaded?.data?.data?.path}"`, "agdsg")

      setFile(uploaded?.data?.data?.path)
    }
  }
  async function handleCountry(data) {
    setCountry(data)
    const cities = await address?.filter(item => item.label === data)[0]?.children
    setCity("")
    setDisctrict("")
    setCityList([ ...cities.map(item => item.label)])
  }
  async function handleCity(data) {
    setCity(data)
    setDisctrict("")
    const cities = await address?.filter(item => item.label === (country ? country : 'Монгол улс'))[0]?.children
    const districts = await cities?.filter(item => item.label === (data ? data : 'Улаанбаатар хот'))[0]?.children
    setDistrictList([ ...districts.map(item => item.label)])
  }
  function handleDistrict(data) {
    setDisctrict(data)
  }

  const merchantUpdate = async () => {
    const merchantData = {
      registration_number: merchantReg,
      name: merchantName,
      contract_type: contractType,
      contract_url: file,
      "owner_info.mobile_2": ownerBankType,
      "owner_info.registration_number": ownerReg,
      "owner_info.name": ownerName,
      "owner_info.mobile_1": ownerNumber,
      "owner_info.email": ownerEmail,
      "owner_info.bank_account": ownerBank,
      phone_number: merchantNumber,
      country: country,
      city: city,
      districy: district,
      "address.address_string": desc,
      "address.position.coordinates": [coordinates1, coordinates2],
    }
    try {
      const upadatedMerchant = await updateMerchant(
        branch?.merchant?._id,
        merchantData
      )
      if (upadatedMerchant.status === "error") throw upadatedMerchant
      else toastr.success("Амжилттай")
      window.location.reload()
    } catch (err) {
      toastr.error(err.message)
      console.log(err.message, "errror")
    }
  }
  const branchUpdate = async () => {
    const branchData = {
      shortcode: uaCode,
      name: name,
      cash_vendor: cashVendor,
      pos_number: cashNo,
      timetable: timeTable,
      status: status,
      "qrs.shop_tier": type,
      reason_of_closing: closeDesc,
      does_have_address: does_have_address,
      address_date: address_date,
      address_type: address_type,
      other_decorations: other_decorations,
    }
    // if (branchValidate()) {
    try {
      const updatedBrach = await updateBranch(
        branch?.merchant?._id,
        branch?._id,
        branchData
      )
      if (updatedBrach.status === "error") throw updatedBrach
      else toastr.success("Амжилттай")
      window.location.reload()
    } catch (err) {
      toastr.error(err.message)
      console.log(err.message, "errror")
    }
    // }
  }
  const addPos = async () => {
    try {
      const createdPos = await createPos(branch?.merchant?._id, branch?._id)

      if (createdPos.status === "error") throw createdPos
      else toastr.success("Амжилттай үүслээ")
      window.location.reload()
    } catch (err) {
      toastr.error(err.message)
      console.log(err, "errror")
    }
  }
  const posUpdate = async () => {
    const posData = {
      status: pos_status,
    }
    try {
      const updatedPos = await updatePos(
        branch?.merchant?._id,
        branch?._id,
        branch?.pos[posId]._id,
        posData
      )
      if (updatedPos.status === "error") throw updatedPos
      else toastr.success("Амжилттай")
      window.location.reload()
    } catch (err) {
      a
      toastr.error(err.message)
      console.log(err, "errror")
    }
  }
  // const branchValidate = () => {
  //   let uaC = uaCode
  //   let errors = {}
  //   let isValid = true
  //   if (uaC === "") {
  //     isValid = false

  //     errors["uaC"] = "Заавал утга оруулна уу"
  //   }
  //   setErrors(errors)
  //   return isValid
  // }

  return (
    <React.Fragment>
      <Row className="justify-content-center">
        <Col xl={11}>
          <div>
            <div ref={bottomRef} />
            <Card>
              <CardBody>
                <CardTitle>Посын мэдээлэл</CardTitle>
                <Row className="align-items-center">
                  <Col>
                    <div className="table-responsive">
                      <Col>
                        <Row>
                          <Col md={10}></Col>
                          <Col>
                            <Button
                              color="info"
                              className="btn btn-info "
                              onClick={setmodal_createPos}
                              style={{ marginLeft: "50px" }}
                            >
                              ПОС үүсгэх
                            </Button>
                            <Modal
                              isOpen={modal_createPos}
                              toggle={() => {
                                tog_createPos()
                              }}
                            >
                              <div className="modal-header">
                                <h5
                                  className="modal-title mt-0"
                                  id="myLargeModalLabel"
                                >
                                  Шинээр ПОС үүсгэх гэж байна
                                </h5>
                                <button
                                  onClick={() => {
                                    setmodal_createPos(false)
                                  }}
                                  type="button"
                                  className="close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div className="modal-footer">
                                <Row>
                                  <Col>
                                    <Button
                                      color="info"
                                      className="btn btn-info "
                                      onClick={addPos}
                                      style={{ marginLeft: "50px" }}
                                    >
                                      Тийм
                                    </Button>
                                  </Col>
                                  <Col>
                                    <Button
                                      color="info"
                                      className="btn btn-info "
                                      onClick={() => {
                                        setmodal_createPos(false)
                                      }}
                                      style={{ marginLeft: "50px" }}
                                    >
                                      Үгүй
                                    </Button>
                                  </Col>
                                </Row>
                              </div>
                            </Modal>
                          </Col>
                        </Row>

                        <Modal
                          size="lg"
                          isOpen={modal_standard}
                          toggle={() => {
                            tog_standard()
                          }}
                        >
                          <div className="modal-header">
                            <h5
                              className="modal-title mt-0"
                              id="myLargeModalLabel"
                            >
                              Посын мэдээлэл
                            </h5>
                            <button
                              onClick={() => {
                                setmodal_standard(false)
                              }}
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <Row>
                              <Card>
                                <CardBody>
                                  {branch?.pos?.length > 0 ? (
                                    <div>
                                      <CardTitle>
                                        POS ID : {branch?.pos[posId]?._id}
                                      </CardTitle>
                                      <Row className="align-items-center">
                                        <Col>
                                          <h6>
                                            Үүсгэсэн огноо:{" "}
                                            {branch?.pos[posId]?.created_at ==
                                            null
                                              ? null
                                              : moment(
                                                  branch?.pos[posId]?.created_at
                                                ).format("L")}
                                          </h6>
                                          <Col>
                                            <label htmlFor="name">
                                              ПОС төлөв:{" "}
                                            </label>
                                          </Col>
                                          <Col md={5}>
                                            <select
                                              className="form-control"
                                              value={pos_status}
                                              onChange={e => {
                                                setPosStatus(e.target.value)
                                              }}
                                            >
                                              <option value={-1}>Ноорог</option>
                                              <option value={1}>
                                                Идэвхтэй
                                              </option>
                                              <option value={0}>
                                                Идэвхгүй
                                              </option>
                                            </select>
                                          </Col>
                                        </Col>
                                        <Col>
                                          <h6>
                                            ОТР:{" "}
                                            {branch?.pos[posId]?.otp?.plain}
                                          </h6>
                                          <h6>
                                            ОТР төлөв:{" "}
                                            {branch?.pos[posId]?.otp
                                              ?.is_validated === true
                                              ? "Ашигласан"
                                              : "Ашиглаагүй"}
                                          </h6>
                                          <h6>
                                            Идэвхжүүлсэн огноо:{" "}
                                            {branch?.pos[posId]?.otp
                                              ?.validated_at == null
                                              ? null
                                              : moment(
                                                  branch?.pos[posId]?.otp
                                                    ?.validated_at
                                                ).format("L")}
                                          </h6>
                                        </Col>
                                      </Row>
                                      {/* <Row>
                                        <Col>
                                          <h6>
                                            ОТР:{" "}
                                            {branch?.pos[posId]?.otp?.plain}
                                          </h6>
                                          <h6>
                                            ОТР төлөв:{" "}
                                            {
                                              branch?.pos[posId]?.otp
                                                ?.is_validated
                                            }
                                          </h6>
                                          <h6>
                                            Идэвхжүүлсэн огноо:{" "}
                                            {
                                              branch?.pos[posId]?.otp
                                                ?.validated_at
                                            }
                                          </h6>
                                        </Col>
                                      </Row> */}
                                    </div>
                                  ) : (
                                    <div></div>
                                  )}
                                </CardBody>
                              </Card>
                            </Row>
                            <Row>
                              <Card>
                                <CardBody>
                                  {branch?.pos?.length > 0 ? (
                                    <div>
                                      <CardTitle>Health Log : </CardTitle>
                                      <Row className="align-items-center">
                                        <Col>
                                          <h6>
                                            Ажиллагааны статус:{" "}
                                            {branch?.pos[posId]?.work_status ===
                                            0 ? (
                                              <Badge
                                                style={{
                                                  backgroundColor: "#7DE86A",
                                                }}
                                              >
                                                Шинэ
                                              </Badge>
                                            ) : branch?.pos[posId]
                                                ?.work_status === 1 ? (
                                              <Badge
                                                style={{
                                                  backgroundColor: "#2DAD35",
                                                }}
                                              >
                                                Хэвийн
                                              </Badge>
                                            ) : branch?.pos[posId]
                                                ?.work_status === 2 ? (
                                              <Badge
                                                style={{
                                                  backgroundColor: "#16591A",
                                                }}
                                              >
                                                Гүйлгээ хэвийн / Агент
                                                ажиллагаагүй
                                              </Badge>
                                            ) : branch?.pos[posId]
                                                ?.work_status === 3 ? (
                                              <Badge
                                                style={{
                                                  backgroundColor: "#07360A",
                                                }}
                                              >
                                                Агент хэвийн / Гүйлгээгүй
                                              </Badge>
                                            ) : branch?.pos[posId]
                                                ?.work_status === 4 ? (
                                              <Badge
                                                style={{
                                                  backgroundColor: "#D6AC0F",
                                                }}
                                              >
                                                Сүүлийн 7 хоног ажиллаагүй
                                              </Badge>
                                            ) : branch?.pos[posId]
                                                ?.work_status === 5 ? (
                                              <Badge
                                                style={{
                                                  backgroundColor: "#E7810D",
                                                }}
                                              >
                                                Сүүлийн 14 хоног ажиллаагүй
                                              </Badge>
                                            ) : branch?.pos[posId]
                                                ?.work_status === 6 ? (
                                              <Badge
                                                style={{
                                                  backgroundColor: "#EB2010",
                                                }}
                                              >
                                                14+ хоног ажиллаагүй
                                              </Badge>
                                            ) : (
                                              <Badge
                                                style={{
                                                  backgroundColor: "#EB2010",
                                                }}
                                              >
                                                14+ хоног ажиллаагүй
                                              </Badge>
                                            )}
                                          </h6>
                                          <h6>
                                            Сүүлд гүйлгээ шидсэн огноо:{" "}
                                            {branch?.pos[posId]
                                              ?.last_transaction_date == null
                                              ? null
                                              : moment(
                                                  branch?.pos[posId]
                                                    ?.last_transaction_date
                                                ).format("L")}
                                          </h6>
                                          <h6>
                                            Сүүлд sync хийсэн огноо:{" "}
                                            {branch?.pos[posId]
                                              ?.last_sync_date == null
                                              ? null
                                              : moment(
                                                  branch?.pos[posId]
                                                    ?.last_sync_date
                                                ).format("L")}
                                          </h6>
                                          <h6>Гэмтэлтэй хоног: </h6>
                                        </Col>

                                        <Col>
                                          <h6>
                                            UA Agent хувилбар:{" "}
                                            {
                                              branch?.pos[posId]?.health
                                                ?.ua_agent_version
                                            }
                                          </h6>
                                          <h6>
                                            UA Agent цаг:
                                            {
                                              branch?.pos[posId]?.health
                                                ?.ua_agent_time
                                            }
                                          </h6>
                                          <h6>
                                            QR кодын үлдэгдэл:
                                            {
                                              branch?.pos[posId]?.health
                                                ?.upoint_code_stock
                                            }
                                          </h6>
                                          <h6>
                                            Windows хувилбар:{" "}
                                            {
                                              branch?.pos[posId]?.health
                                                ?.windows_version
                                            }
                                          </h6>
                                          <h6>
                                            CPU:{" "}
                                            {branch?.pos[posId]?.health?.CPU
                                              .length == 0
                                              ? null
                                              : branch?.pos[posId]?.health
                                                  ?.CPU[0]?.model}
                                          </h6>
                                          <h6>
                                            Дискний хэмжээ:{" "}
                                            {
                                              branch?.pos[posId]?.health
                                                ?.disk_size
                                            }
                                          </h6>
                                        </Col>
                                      </Row>
                                      <div className="modal-footer">
                                        <button
                                          type="button"
                                          className="btn btn-primary "
                                          onClick={posUpdate}
                                        >
                                          Save changes
                                        </button>
                                      </div>
                                    </div>
                                  ) : (
                                    <div></div>
                                  )}
                                </CardBody>
                              </Card>
                            </Row>
                          </div>
                        </Modal>
                      </Col>
                      <Table className="align-middle mb-0">
                        <thead>
                          <tr>
                            <th>POS ID</th>
                            <th>Үүсгэсэн огноо</th>
                            <th>Статус</th>
                            <th>Сүүлд sync хийсэн огноо</th>
                            <th>Сүүлд гүйлгээ шидсэн огноо</th>
                            <th>ПОС төлөв</th>
                            <th>Гэмтэлтэй хоног</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {branch?.pos?.map(
                            (
                              {
                                work_status,
                                otp,
                                status,
                                _id,
                                last_transaction_date,
                                last_sync_date,
                                created_at,
                              },
                              id
                            ) => {
                              return (
                                <tr key={id}>
                                  <th>{_id}</th>
                                  <td>
                                    {" "}
                                    {created_at == null
                                      ? null
                                      : moment(created_at).format("L")}
                                  </td>
                                  <td>
                                    {branch?.pos[posId]?.work_status === 0 ? (
                                      <Badge
                                        style={{
                                          backgroundColor: "#7DE86A",
                                        }}
                                      >
                                        Шинэ
                                      </Badge>
                                    ) : branch?.pos[posId]?.work_status ===
                                      1 ? (
                                      <Badge
                                        style={{
                                          backgroundColor: "#2DAD35",
                                        }}
                                      >
                                        Хэвийн
                                      </Badge>
                                    ) : branch?.pos[posId]?.work_status ===
                                      2 ? (
                                      <Badge
                                        style={{
                                          backgroundColor: "#16591A",
                                        }}
                                      >
                                        Гүйлгээ хэвийн / Агент ажиллагаагүй
                                      </Badge>
                                    ) : branch?.pos[posId]?.work_status ===
                                      3 ? (
                                      <Badge
                                        style={{
                                          backgroundColor: "#07360A",
                                        }}
                                      >
                                        Агент хэвийн / Гүйлгээгүй
                                      </Badge>
                                    ) : branch?.pos[posId]?.work_status ===
                                      4 ? (
                                      <Badge
                                        style={{
                                          backgroundColor: "#D6AC0F",
                                        }}
                                      >
                                        Сүүлийн 7 хоног ажиллаагүй
                                      </Badge>
                                    ) : branch?.pos[posId]?.work_status ===
                                      5 ? (
                                      <Badge
                                        style={{
                                          backgroundColor: "#E7810D",
                                        }}
                                      >
                                        Сүүлийн 14 хоног ажиллаагүй
                                      </Badge>
                                    ) : branch?.pos[posId]?.work_status ===
                                      6 ? (
                                      <Badge
                                        style={{
                                          backgroundColor: "#EB2010",
                                        }}
                                      >
                                        14+ хоног ажиллаагүй
                                      </Badge>
                                    ) : (
                                      <Badge
                                        style={{
                                          backgroundColor: "#EB2010",
                                        }}
                                      >
                                        14+ хоног ажиллаагүй
                                      </Badge>
                                    )}
                                  </td>
                                  <td>
                                    {last_transaction_date == null
                                      ? null
                                      : moment(last_transaction_date).format(
                                          "L"
                                        )}
                                  </td>
                                  <td>
                                    {" "}
                                    {last_sync_date == null
                                      ? null
                                      : moment(last_sync_date).format("L")}
                                  </td>
                                  <td>
                                    {status === 1
                                      ? "Идэвхтэй"
                                      : status === 0
                                      ? "Идэвхгүй"
                                      : "Ноорог"}
                                  </td>
                                  <td></td>
                                  <td>
                                    <button
                                      type="button"
                                      className="btn btn-light btn-sm"
                                      onClick={() => {
                                        setPosId(id)
                                        setPosStatus(
                                          branch?.pos
                                            ? branch?.pos[id]?.status
                                            : ""
                                        )
                                        tog_standard()
                                      }}
                                    >
                                      View
                                    </button>
                                  </td>
                                </tr>
                              )
                            }
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Row>
              <Col md={6}>
                <Card>
                  <CardBody>
                    <CardTitle>Дэлгүүрийн мэдээлэл : </CardTitle>

                    <Row className="align-items-center">
                      <Col>
                        <h6>UA Код: {branch.shortcode}</h6>
                        <h6>Нэр: {branch.name}</h6>
                        <h6>
                          Кассын систем:{" "}
                          {branch?.misc_info?.cash_vendor?.length === 0
                            ? " "
                            : branch?.misc_info?.cash_vendor[0]?.name}
                        </h6>
                        <h6>Кассын тоо: {branch?.pos?.length}</h6>
                        <h6>Цагийн хуваарь:{branch.timetable}</h6>
                      </Col>

                      <Col>
                        <h6>
                          Төлөв:{" "}
                          {status === 0
                            ? "Хаагдсан"
                            : branch.status === 1
                            ? "Нээлттэй"
                            : "Ноорог"}{" "}
                        </h6>
                        <h6>Ангилал: {branch?.qrs?.shop_tier.name}</h6>
                        <h6>Хаагдсан шалтгаан:</h6>{" "}
                        {status === 0 ? (
                          <h6>{branch?.reason_of_closing}</h6>
                        ) : (
                          <h6></h6>
                        )}
                      </Col>
                      <div>
                        <h6>
                          Хаяг тавигдсан эсэх:{" "}
                          {does_have_address == 1 ? "Тийм" : "Үгүй"}
                        </h6>
                      </div>
                      {does_have_address == 1 ? (
                        <Row>
                          <Col>
                            <h6>Хаяг тавьсан огноо: {branch?.address_date}</h6>
                            <h6>Хаяг төрөл: {branch?.address_type}</h6>
                            <div>
                              <h6>
                                Бусад тохижилт: {branch?.other_decorations}
                              </h6>
                            </div>
                          </Col>
                        </Row>
                      ) : (
                        <div></div>
                      )}
                    </Row>
                    <Row>
                      <Col md={20}></Col>
                      <Col md={1}>
                        <Button onClick={tog_branch}>Засах</Button>
                      </Col>
                    </Row>
                    <Modal
                      isOpen={modal_branch}
                      toggle={() => {
                        tog_branch()
                      }}
                    >
                      <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">
                          Дэлгүүрийн мэдээлэл
                        </h5>
                        <button
                          type="button"
                          onClick={() => {
                            setmodal_branch(false)
                          }}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>

                      <form
                        className="needs-validation"
                        onSubmit={branchUpdate}
                      >
                        <div className="modal-body">
                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              UA код
                            </label>
                            <div className="col-md-10">
                              <input
                                className="form-control"
                                type="text"
                                value={uaCode}
                                disabled
                                onChange={e => {
                                  setUaCode(e.target.value)
                                }}
                              />
                            </div>
                          </Row>
                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Нэр
                            </label>
                            <div className="col-md-10">
                              <input
                                className="form-control"
                                type="text"
                                required
                                value={name}
                                onChange={e => {
                                  setName(e.target.value)
                                }}
                              />
                            </div>
                            <div
                              className="text-danger"
                              style={{ marginLeft: "220px" }}
                            >
                              {errors.uaC}
                            </div>
                          </Row>
                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Кассын систем
                            </label>
                            <div className="col-md-10">
                              <select
                                className="form-control"
                                type="text"
                                value={cashVendor}
                                onChange={e => {
                                  setCashVendor(e.target.value)
                                }}
                              >
                                {pos.map(param => {
                                  return (
                                    <option
                                      key={param._id}
                                      name={param.name}
                                      value={param._id}
                                    >
                                      {param.name}
                                    </option>
                                  )
                                })}
                              </select>
                            </div>
                          </Row>
                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Кассын тоо
                            </label>
                            <div className="col-md-10">
                              <input
                                className="form-control"
                                type="text"
                                disabled
                                value={cashNo}
                                onChange={e => {
                                  setCashNo(e.target.value)
                                }}
                              />
                            </div>
                          </Row>
                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Цагийн хуваарь
                            </label>
                            <div className="col-md-10">
                              <input
                                className="form-control"
                                type="text"
                                value={timeTable}
                                onChange={e => {
                                  setTimeTable(e.target.value)
                                }}
                              />
                            </div>
                          </Row>
                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Төлөв
                            </label>
                            <div className="col-md-10">
                              <select
                                className="form-control"
                                type="text"
                                value={status}
                                onChange={e => {
                                  setStatus(e.target.value)
                                }}
                              >
                                <option value={-1}>Ноорог</option>
                                <option value={1}>Нээлттэй</option>
                                <option value={0}>Хаалттай</option>
                              </select>
                            </div>
                          </Row>
                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Ангилал
                            </label>
                            <div className="col-md-10">
                              <select
                                className="form-control"
                                type="text"
                                value={type}
                                onChange={e => {
                                  setType(e.target.value)
                                }}
                              >
                                <option value={"5ea6a140b06ae101d99daa58"}>
                                  A
                                </option>
                                <option value={"5ea6a15358139f02157b2470"}>
                                  B
                                </option>
                                <option value={"5ea6a1665dfe14023d38eaca"}>
                                  C
                                </option>
                                <option value={"5f111739b4a249261d284455"}>
                                  Test
                                </option>
                              </select>
                            </div>
                          </Row>
                          {status == 0 && (
                            <Row className="mb-3">
                              <label
                                htmlFor="example-text-input"
                                className="col-md-2 col-form-label"
                              >
                                Хаагдсан шалтгаан
                              </label>
                              <div className="col-md-10">
                                <input
                                  className="form-control"
                                  type="text"
                                  required
                                  value={closeDesc}
                                  onChange={e => {
                                    setCloseDesc(e.target.value)
                                  }}
                                />
                              </div>
                            </Row>
                          )}
                          <div>
                            <Row className="mb-3">
                              <label
                                htmlFor="example-text-input"
                                className="col-md-2 col-form-label"
                              >
                                Хаяг тавигдсан эсэх:
                              </label>
                              <div className="col-md-10">
                                <select
                                  className="form-control"
                                  type="text"
                                  name="does_have_address"
                                  value={does_have_address}
                                  onChange={e => {
                                    SetAddress(e.target.value)
                                  }}
                                >
                                  <option value={-1}>--Select Type--</option>
                                  <option value={1}>Тийм</option>
                                  <option value={0}>Үгүй</option>
                                </select>
                              </div>
                            </Row>
                          </div>
                          {does_have_address == 1 && (
                            <div>
                              <Row className="mb-3">
                                <label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Хаяг тавьсан огноо:
                                </label>
                                <div className="col-md-10">
                                  <input
                                    className="form-control"
                                    type="date"
                                    value={address_date}
                                    onChange={e => {
                                      SetAddressDate(e.target.value)
                                    }}
                                  />
                                </div>
                              </Row>
                              <Row className="mb-3">
                                <label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Хаяг төрөл
                                </label>
                                <div className="col-md-10">
                                  <input
                                    name={"address_type"}
                                    className="form-control"
                                    value={address_type}
                                    onChange={e => {
                                      SetAddressType(e.target.value)
                                    }}
                                  />
                                  {/* <Select
                                value={branch.address_type}
                                isMulti
                                name="address_type"
                                onChange={handleMulti}
                                options={optionGroup}
                                classNamePrefix="select2-selection"
                              /> */}
                                </div>
                              </Row>
                              <Row className="mb-3">
                                <label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Бусад тохижилт:
                                </label>
                                <div className="col-md-10">
                                  <input
                                    className="form-control"
                                    name={"other_decorations"}
                                    value={other_decorations}
                                    onChange={e => {
                                      SetOtherDecoration(e.target.value)
                                    }}
                                  />
                                </div>
                              </Row>
                            </div>
                          )}
                        </div>
                        <div className="modal-footer">
                          <button type="submit" className="btn btn-primary ">
                            Save changes
                          </button>
                        </div>
                      </form>
                    </Modal>
                  </CardBody>
                </Card>
              </Col>
              <Col md={6}>
                <Card>
                  <CardBody>
                    <CardTitle>Хаягийн мэдээлэл : </CardTitle>
                    <Row className="align-items-center">
                      <Col>
                        <h6>Улс: {country}</h6>
                        <h6>Аймаг, хот: {city}</h6>
                        <h6>Баг хороо: {district1}</h6>
                        <h6>Сум, дүүрэг: {district}</h6>
                        <h6>
                          Дэлгэрэнгүй:{" "}
                          {branch?.merchant?.address?.address_string}
                        </h6>
                        <h6>
                          Уртраг:
                          {branch?.merchant?.address?.position?.coordinates[0]}{" "}
                        </h6>
                        <h6>
                          Өргөрөг:
                          {branch?.merchant?.address?.position?.coordinates[1]}
                        </h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={20}></Col>
                      <Col md={1}>
                        <Button onClick={tog_address}>Засах</Button>
                      </Col>
                    </Row>
                    <Modal
                      isOpen={modal_address}
                      toggle={() => {
                        tog_address()
                      }}
                    >
                      <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">
                          Хаягийн мэдээлэл
                        </h5>
                        <button
                          type="button"
                          onClick={() => {
                            setmodal_address(false)
                          }}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <form
                        className="needs-validation"
                        onSubmit={merchantUpdate}
                      >
                        <div className="modal-body">
                          {/* <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Улс
                            </label>
                            <div className="col-md-10">
                              <input
                                className="form-control"
                                type="text"
                                value={country}
                                onChange={e => {
                                  setCountry(e.target.value)
                                }}
                              />
                            </div>
                          </Row>
                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Аймаг, хот
                            </label>
                            <div className="col-md-10">
                              <input
                                className="form-control"
                                type="text"
                                value={city}
                                onChange={e => {
                                  setCity(e.target.value)
                                }}
                              />
                            </div>
                          </Row>
                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Сум, дүүрэг
                            </label>
                            <div className="col-md-10">
                              <input
                                className="form-control"
                                type="text"
                                value={district}
                                onChange={e => {
                                  setDisctrict(e.target.value)
                                }}
                              />
                            </div>
                          </Row> */}
                          <Row className="mb-3">
                            <label htmlFor="example-text-input" className="col-md-2 col-form-label">Улс</label>
                            <div className="col-md-10">
                              <Dropdown isOpen={dropdownCountyOpen} toggle={toggleCountry}>
                                <DropdownToggle style={{ width: '100%'}} caret>{country ? country: 'Улсаа сонгоно уу.'}</DropdownToggle>
                                <DropdownMenu style={{ width: '100%'}}>
                                  {
                                    countryList?.map((item, index) => {
                                      return (
                                        <DropdownItem key={index} onClick={() => {
                                          handleCountry(item)
                                        }}>{item}</DropdownItem>
                                      )
                                    })
                                  }
                                </DropdownMenu>
                              </Dropdown>
                            </div>
                          </Row>
                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Аймаг, хот
                            </label>
                            <div className="col-md-10" >
                              <Dropdown  isOpen={dropdownCityOpen} toggle={toggleCity}>
                                <DropdownToggle style={{ width: '100%'}}  caret>{city ? city: 'Аймаг, хотоо сонгоно уу.'}</DropdownToggle>
                                <DropdownMenu style={{ width: '100%'}}>
                                  {
                                    cityList?.map((item, index) => {
                                      return (
                                        <DropdownItem key={index} onClick={() => {
                                          handleCity(item)
                                        }}>{item}</DropdownItem>
                                      )
                                    })
                                  }
                                </DropdownMenu>
                              </Dropdown>
                            </div>
                          </Row>
                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Сум, дүүрэг
                            </label>
                            <div className="col-md-10">
                              <Dropdown isOpen={dropdownDistrictOpen} toggle={toggleDistrict}>
                                <DropdownToggle style={{ width: '100%'}} caret>{district ? district : "Сум, дүүрэгээ оруулна уу."}</DropdownToggle>
                                <DropdownMenu style={{ width: '100%'}}>
                                  {
                                    districtList?.map((item, index) => {
                                      return (
                                        <DropdownItem key={index} onClick={() => {
                                          handleDistrict(item)
                                        }}>{item}</DropdownItem>
                                      )
                                    })
                                  }
                                </DropdownMenu>
                              </Dropdown>
                            </div>
                          </Row>
                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Баг, хороо
                            </label>
                            <div className="col-md-10">
                              <input
                                className="form-control"
                                type="text"
                                value={district1}
                                onChange={e => {
                                  setDisctrict1(e.target.value)
                                }}
                              />
                            </div>
                          </Row>
                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Дэлгэрэнгүй
                            </label>
                            <div className="col-md-10">
                              <input
                                className="form-control"
                                type="text"
                                required
                                value={desc}
                                onChange={e => {
                                  setDesc(e.target.value)
                                }}
                              />
                            </div>
                          </Row>
                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Уртраг
                            </label>
                            <div className="col-md-10">
                              <input
                                className="form-control"
                                type="number"
                                step="0.00001"
                                required
                                value={coordinates1}
                                onChange={e => {
                                  setCoordinates1(e.target.value)
                                }}
                              />
                            </div>
                          </Row>
                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Өргөрөг
                            </label>
                            <div className="col-md-10">
                              <input
                                className="form-control"
                                required
                                step="0.00001"
                                type="number"
                                value={coordinates2}
                                onChange={e => {
                                  setCoordinates2(e.target.value)
                                }}
                              />
                            </div>
                          </Row>
                        </div>
                        <div className="modal-footer">
                          <button type="submit" className="btn btn-primary ">
                            Save changes
                          </button>
                        </div>
                      </form>
                    </Modal>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Card>
                  <CardBody>
                    <CardTitle>Байгууллагын мэдээлэл : </CardTitle>
                    <Row className="align-items-center">
                      <Col>
                        <h6>
                          Байгууллагын регистр:{" "}
                          {branch.merchant?.registration_number}
                        </h6>
                        <h6>Байгууллагын нэр: {branch.merchant?.name}</h6>
                        <h6>MC код: {branch.merchant?.shortcode}</h6>
                        <h6>Core merchant ID: {branch.merchant?.merchant}</h6>
                      </Col>
                      <Col>
                        <h6>
                          Гэрээний төрөл:{" "}
                          {branch?.merchant?.contract_type === 0
                            ? "Цахим"
                            : "Цаасан"}
                        </h6>
                        <h6>
                          Цаасан гэрээний хуулбар:{" "}
                          <a
                            target="_blank"
                            href={branch?.merchant?.contract_url}
                          >
                            {branch?.merchant?.contract_url}
                          </a>
                        </h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={20}></Col>
                      <Col md={1}>
                        <Button onClick={tog_merchant}>Засах</Button>
                      </Col>
                    </Row>
                    <Modal
                      isOpen={modal_merchant}
                      toggle={() => {
                        tog_merchant()
                      }}
                    >
                      <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">
                          Байгууллагын мэдээлэл
                        </h5>
                        <button
                          type="button"
                          onClick={() => {
                            setmodal_merchant(false)
                          }}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <form
                        className="needs-validation"
                        onSubmit={merchantUpdate}
                      >
                        <div className="modal-body">
                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Байгууллагын регистр
                            </label>
                            <div className="col-md-10">
                              <input
                                className="form-control"
                                type="text"
                                required
                                maxlength="10"
                                value={merchantReg}
                                onChange={e => {
                                  setMerchantReg(e.target.value)
                                }}
                              />
                            </div>
                          </Row>
                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Байгууллагын нэр
                            </label>
                            <div className="col-md-10">
                              <input
                                className="form-control"
                                required
                                type="text"
                                value={merchantName}
                                onChange={e => {
                                  setMerchantName(e.target.value)
                                }}
                              />
                            </div>
                          </Row>
                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              MC код
                            </label>
                            <div className="col-md-10">
                              <input
                                className="form-control"
                                type="text"
                                disabled
                                value={mcCode}
                                onChange={e => {
                                  setMcCode(e.target.value)
                                }}
                              />
                            </div>
                          </Row>
                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Core merchant ID
                            </label>
                            <div className="col-md-10">
                              <input
                                className="form-control"
                                type="text"
                                disabled
                                value={merchantId}
                                onChange={e => {
                                  setMerchantId(e.target.value)
                                }}
                              />
                            </div>
                          </Row>
                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Гэрээний төрөл
                            </label>
                            <div className="col-md-10">
                              <select
                                className="form-control"
                                type="text"
                                value={contractType}
                                onChange={e => {
                                  setContractType(e.target.value)
                                }}
                              >
                                <option value={0}>Цахим</option>
                                <option value={1}>Цаасан</option>
                              </select>
                            </div>
                          </Row>

                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Гэрээний хуулбар
                            </label>
                            <div className="col-md-10">
                              <Input
                                className="form-control"
                                type="file"
                                id="formFile"
                                onChange={uploadFile}
                              />
                            </div>
                          </Row>
                        </div>
                        <div className="modal-footer">
                          <button type="submit" className="btn btn-primary ">
                            Save changes
                          </button>
                        </div>
                      </form>
                    </Modal>
                  </CardBody>
                </Card>
              </Col>
              <Col md={6}>
                <Card>
                  <CardBody>
                    <CardTitle>Эзэмшигчийн мэдээлэл : </CardTitle>
                    <Row className="align-items-center">
                      <Col>
                        <h6>
                          Регистрийн дугаар:{" "}
                          {branch?.merchant?.owner_info?.registration_number}
                        </h6>
                        <h6>Нэр: {branch?.merchant?.owner_info?.name}</h6>
                        <h6>
                          Эзэмшигчийн утас:{" "}
                          {branch?.merchant?.owner_info?.mobile_1}
                        </h6>
                        <h6>
                          Дэлгүүрийн / худалдагчийн утас:{" "}
                          {branch?.merchant?.phone_number}
                        </h6>
                      </Col>
                      <Col>
                        <h6>Имэйл: {branch?.merchant?.owner_info?.email}</h6>
                        <h6>
                          {" "}
                          Банк:
                          {branch?.merchant?.owner_info?.mobile_2 === "1"
                            ? "Хаан"
                            : branch?.merchant?.owner_info?.mobile_2 === "2"
                            ? "Голомт"
                            : branch?.merchant?.owner_info?.mobile_2 === "3"
                            ? "Хас"
                            : "TDB"}{" "}
                        </h6>
                        <h6>
                          Дансны дугаар:{" "}
                          {branch?.merchant?.owner_info?.bank_account}
                        </h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={20}></Col>
                      <Col md={1}>
                        <Button onClick={tog_owner}>Засах</Button>
                      </Col>
                    </Row>
                    <Modal
                      isOpen={modal_owner}
                      toggle={() => {
                        tog_owner()
                      }}
                    >
                      <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">
                          Эзэмшигчийн мэдээлэл
                        </h5>
                        <button
                          type="button"
                          onClick={() => {
                            setmodal_owner(false)
                          }}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <form
                        className="needs-validation"
                        onSubmit={merchantUpdate}
                      >
                        <div className="modal-body">
                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Регистрийн дугаар
                            </label>

                            <div className="col-md-10">
                              <input
                                className="form-control"
                                required
                                maxlength="10"
                                type="text"
                                value={ownerReg}
                                onChange={e => {
                                  setOwnerReg(e.target.value)
                                }}
                              />
                            </div>
                          </Row>
                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Нэр
                            </label>
                            <div className="col-md-10">
                              <input
                                className="form-control"
                                type="text"
                                value={ownerName}
                                required
                                onChange={e => {
                                  setOwnerName(e.target.value)
                                }}
                              />
                            </div>
                          </Row>
                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Имэйл
                            </label>
                            <div className="col-md-10">
                              <input
                                className="form-control"
                                type="text"
                                value={ownerEmail}
                                onChange={e => {
                                  setOwnerEmail(e.target.value)
                                }}
                              />
                            </div>
                          </Row>
                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Банк
                            </label>
                            <div className="col-md-10">
                              <select
                                className="form-control"
                                value={ownerBankType}
                                onChange={e => setOwnerBankType(e.target.value)}
                              >
                                <option value={1}>Хаан</option>
                                <option value={2}>Голомт</option>
                                <option value={3}>Хас</option>
                                <option value={4}>TDB</option>
                              </select>
                              {/* <input
                                className="form-control"
                                type="text"
                                required
                                value={ownerBankType}
                                onChange={e => 
                                  setOwnerBankType(e.target.value)
                                }
                              /> */}
                            </div>
                          </Row>
                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Дансны дугаар
                            </label>
                            <div className="col-md-10">
                              <input
                                className="form-control"
                                type="text"
                                required
                                value={ownerBank}
                                onChange={e => {
                                  setOwnerBank(e.target.value)
                                }}
                              />
                            </div>
                          </Row>
                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Эзэмшигчийн утас
                            </label>
                            <div className="col-md-10">
                              <input
                                className="form-control"
                                type="text"
                                required
                                value={ownerNumber}
                                onChange={e => {
                                  setOwnerNumber(e.target.value)
                                }}
                              />
                            </div>
                          </Row>
                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Дэлгүүрийн / худалдагчийн утас:
                            </label>
                            <div className="col-md-10">
                              <input
                                className="form-control"
                                type="text"
                                value={merchantNumber}
                                onChange={e => {
                                  setMerchantNumber(e.target.value)
                                }}
                              />
                            </div>
                          </Row>
                        </div>
                        <div className="modal-footer">
                          <button type="submit" className="btn btn-primary ">
                            Save changes
                          </button>
                        </div>
                      </form>
                    </Modal>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default TabOne
