import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { getPos, getShop, getBranch, getAddressOptions } from "../../../services/mcp.service"

import {
  Container,
  Card,
  CardBody,
  CardTitle,
  Col,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

import classnames from "classnames"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import TabOne from "./TabOne"
import TabTwo from "./tabTwo"
// import RightBar from "./RightBar"

const Info = props => {
  const [activeTab, toggleTab] = useState("1")
  const [shops, setShops] = useState([])
  const [pos, setPos] = useState([])
  const [branch, setBranch] = useState({})
  const [address, setAddress] = useState([])

  const toggle = tab => {
    if (activeTab !== tab) toggleTab(tab)
  }

  useEffect(async () => {
    try {
      const pos = await getPos()
      const branchComing = await getBranch(
        props.match.params.merchant,
        props.match.params.id
      )
      const address = await getAddressOptions()
      setAddress(address)
      setBranch(branchComing.data)
    } catch (err) {
      console.log(err)
    }
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Blog" breadcrumbItem="Дэлгэрэнгүй мэдээлэл" />
          {/* <Button onClick={mai()}>
            safd
          </Button> */}
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle className="h4">{branch.name}</CardTitle>

                  <ul
                    className="nav nav-tabs nav-tabs-custom justify-content-center pt-2"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        to="#"
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggleTab("1")
                        }}
                      >
                        Бүртгэл, мэдээлэл
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        to="#"
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          toggleTab("2")
                        }}
                      >
                        Лог тэмдэглэл
                      </NavLink>
                    </NavItem>
                  </ul>
                </CardBody>
              </Card>
              <TabContent className="p-4" activeTab={activeTab}>
                <TabPane tabId="1">
                  <div>
                    <TabOne branch={branch} address={address} />
                  </div>
                </TabPane>

                <TabPane tabId="2">
                  <TabTwo branch={branch} setBranch={setBranch} />
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Info
