import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
import { authService } from "services/auth.service"
import jwt_decode from "jwt-decode"
//i18n
import { withTranslation } from "react-i18next"
import Channel from "./Menus/Channel"
import Business from "./Menus/Business"
const SidebarContent = props => {
  const [role, setRole] = useState("")
  const ref = useRef()
  const token = authService.accessToken
  const decoded = jwt_decode(token)
  console.log(decoded, "decoded")
  useEffect(() => {
    setRole(decoded.role)
  }, [])
  console.log(role, "role")

  console.log(props, "props")
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          {role == "business" ? (
            <Business props={props} />
          ) : role == "channel_pro" || "channel_basic" ? (
            <Channel props={props} />
          ) : (
            "ok"
          )}
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
