import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Col,
  Card,
  CardBody,
  CardTitle,
  Row,
  Form,
  Button,
} from "reactstrap"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import { getPos, getLog } from "../../../services/mcp.service"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import * as FileSaver from "file-saver"
import * as XLSX from "xlsx"

const Log = props => {
  const columns = [
    {
      dataField: "created_at",
      text: "Огноо",
      sort: true,
    },
    {
      dataField: "name",
      text: "Төрөл",
      sort: true,
    },
    {
      dataField: "success",
      text: "Хариу утга",
      sort: true,
    },
    {
      dataField: "employee",
      text: "Хэрэглэгч",
      sort: true,
    },
  ]

  const [pos, setPos] = useState([])
  const [logs, setLogs] = useState([])
  const [uaCode, setUaCode] = useState("")
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")

  useEffect(async () => {
    try {
      const pos = await getPos()
      const logs = await getLog()
      console.log(pos.data, "pos")
      setPos(pos.data)
      setLogs(logs.data)
      console.log(logs.data)
    } catch (err) {
      console.log(err)
    }
  }, [])
  const pageOptions = {
    sizePerPage: 10,
    // totalSize: productData.length, // replace later with size(customers),
    custom: true,
  }
  const selectRow = {
    mode: "checkbox",
  }
  const saveExcel = () => {
    getLog()
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
    const ws = XLSX.utils.json_to_sheet(logs)
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, "Log" + ".xlsx")
    toastr.success("Амжилттай татагдлаа")
  }
  const { SearchBar } = Search
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Лог Шүүх</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Tables" breadcrumbItem="ЛОГ" />

          {/* <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">Дэлгүүрийн Жагсаалт </CardTitle>
                  <Row>
                    <div data-repeater-list="group-a">
                      <div data-repeater-item className="row">
                        <div className="mb-3 col-lg-2">
                          <label htmlFor="name">Эхлэх огноо</label>
                          <input
                            type="date"
                            id="name"
                            name="untyped-input"
                            className="form-control"
                            value={startDate}
                            onChange={e => {
                              setStartDate(e.target.value)
                            }}
                          />
                        </div>
                        <div className="mb-3 col-lg-2">
                          <label htmlFor="email">Дуусах огноо</label>
                          <input
                            type="date"
                            id="email"
                            className="form-control"
                            value={endDate}
                            onChange={e => {
                              setEndDate(e.target.value)
                            }}
                          />
                        </div>

                        <div className="mb-3 col-lg-2">
                          <label htmlFor="subject">ПОС</label>
                          <select
                            className="form-control"
                            type="text"
                            value={posCreate}
                            onChange={e => {
                              setPosCreate(e.target.value)
                            }}
                          >
                            {pos.map(param => {
                              return (
                                <option
                                  key={param._id}
                                  name={param.name}
                                  value={param._id}
                                >
                                  {param.name}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                        <div className="mb-3 col-lg-2">
                          <label htmlFor="subject">UA Код</label>
                          <input
                            type="text"
                            id="subject"
                            className="form-control"
                            onChange={e => {
                              setUaCode(e.target.value)
                            }}
                            value={uaCode}
                          />
                        </div>

                        <Col lg={2} className="align-self-center">
                          <div className="d-grid">
                            <input
                              data-repeater-delete
                              type="button"
                              className="btn btn-primary"
                              value="Шүүх"
                            />
                          </div>
                        </Col>
                      </div>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row> */}
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={columns}
                    data={logs}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={logs}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col md="10">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="2">
                                <Button
                                  color="secondary"
                                  className="btn btn-secondary "
                                  onClick={saveExcel}
                                >
                                  Excel - ээр татах
                                </Button>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    // defaultSorted={defaultSorted}
                                    selectRow={selectRow}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Log
