import React from "react"
import MetaTags from "react-meta-tags"
import { Container } from "reactstrap"
import Table from "./table"

const Coupon = props => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>List of Coupons</title>
        </MetaTags>
        <Container fluid>
          <h3>List of Coupons</h3>
          <Table />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Coupon
