import { BehaviorSubject } from "rxjs"
import axios from "axios"

const subscriber = new BehaviorSubject(localStorage.getItem("access_token"))
// const subscriber = new BehaviorSubject(JSON.parse(localStorage.getItem('access_token')))

const updateAccessToken = function (accessToken) {
  subscriber.next(accessToken)
}

const login = async function (email, password) {
  try {
    let response = await axios({
      url: "https://auth.upoint.mn/internal/login",
      method: "POST",
      data: {
        email,
        password,
        client_id: "8FS20meoLs3r7W",
      },
    })

    if (response.data.status_code === 0) {
      console.log("access_token", response.data.access_token)
      localStorage.setItem("access_token", response.data.access_token)
      localStorage.setItem("view", response.data.views[0])
      updateAccessToken(response.data.access_token)
      return response
    } else {
      throw Error(response.data.error_description)
    }
  } catch (err) {
    console.log(err)
    throw err
  }
}

const logout = async function () {
  try {
    await localStorage.removeItem("access_token")
    await updateAccessToken(null)
    return
  } catch (err) {
    console.log(err)
  }
}

export const authService = {
  login,
  logout,
  updateAccessToken,
  subscriber,
  get accessToken() {
    return subscriber.value
  },
}
